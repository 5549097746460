import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
// import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, allData, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
        columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
        const allRowsHead = []
          const rowItem = [
            { text: vm.$t('external_research.proposal_status'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.getProposalStatusList(search.proposal_status), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        if (search.pub_title_id) {
          const rowItem = [
            { text: vm.$t('research_manage.thematic_area_name'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.getThematicArea(search.thematic_area_id), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.from_date) {
          const rowItem = [
            { text: vm.$t('globalTrans.from_date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(search.from_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.to_date) {
          const rowItem = [
            { text: vm.$t('globalTrans.to_date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(search.to_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['100%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        Object.entries(allData).forEach(([key, reportData]) => {
          const allRows = []
          pdfContent.push({ text: vm.getOfficeTypeName(key) + ',' + vm.getOfficeName(key), style: 'fertilizer' })
          const headerRow = [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('research_manage.project_id'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('research_manage.project_title'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('research_manage.thematic_area'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('external_research.experiment'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('external_research.investigator_name'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('external_research.principal_investigator_name'), style: 'th', alignment: 'center', bold: true }
          ]
          allRows.push(headerRow)
          reportData.forEach((data, index2) => {
            const coInvestigatorsNames = data.co_investigators.map(item => {
              return vm.$i18n.locale === 'en' ? item.investigator_name_en : item.investigator_name_bn
            }).join(', ')
            allRows.push([
              { text: vm.$n(index2 + 1), alignment: 'center', style: 'td' },
              { text: data.proposal_auto_id, alignment: 'center', style: 'td' },
              { text: data.master_project_setup_details ? (vm.$i18n.locale === 'en' ? data.master_project_setup_details.project_name_en : data.master_project_setup_details.project_name_bn) : '', alignment: 'center', style: 'td' },
              { text: vm.getThematicArea(data.thematic_area_id), alignment: 'center', style: 'td' },
              { text: vm.$i18n.locale === 'en' ? data.experiment : data.experiment_bn, alignment: 'center', style: 'td' },
              { text: coInvestigatorsNames, alignment: 'center', style: 'td' },
              { text: vm.getOfficer(data.pi_id), alignment: 'center', style: 'td' }
            ])
          })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['5%', '16%', '16%', '16%', '16%', '16%', '15%'],
              body: allRows
            }
          })
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Landscape',
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 18,
              bold: true,
              margin: [0, -10, 0, -20]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('research-project-report')
    } catch (error) {
      if (error) {
      }
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
